@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border;
  }

  body {
    @apply font-sans bg-white text-black m-0 p-0 text-base;
  }

  .simplebar-scrollbar::before {
    display: none;
  }

  .simplebar-scrollbar .simplebar-scrollbar-thumb {
    background-color: green;
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-right: 108px solid transparent;
    border-bottom: 144px solid #fff;
  }

  .big-circle {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .small-circle {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: absolute;
    transform-origin: center center;
    overflow: hidden;
    cursor: pointer;
  }

  .small-circle:nth-child(1) {
    transform: rotate(0deg) translate(110px) rotate(0deg);
  }

  .small-circle:nth-child(2) {
    transform: rotate(45deg) translate(110px) rotate(-45deg);
  }

  .small-circle:nth-child(3) {
    transform: rotate(90deg) translate(110px) rotate(-90deg);
  }

  .small-circle:nth-child(4) {
    transform: rotate(135deg) translate(110px) rotate(-135deg);
  }

  .small-circle:nth-child(5) {
    transform: rotate(180deg) translate(110px) rotate(-180deg);
  }

  .small-circle:nth-child(6) {
    transform: rotate(225deg) translate(110px) rotate(-225deg);
  }

  .small-circle:nth-child(7) {
    transform: rotate(270deg) translate(110px) rotate(-270deg);
  }

  .small-circle:nth-child(8) {
    transform: rotate(315deg) translate(110px) rotate(-315deg);
  }

  .current-user {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @apply bg-slate-200 w-2/5 h-2/5 rounded-full overflow-hidden;
  }

  .menu-link.active {
    @apply opacity-100 border-b-[2px] border-white;
  }

  .menu-link-mobile.active {
    @apply opacity-100 border-none bg-gray-400 bg-opacity-90;
  }

  .active {
    @apply opacity-60;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(59%) sepia(100%) saturate(2705%) hue-rotate(181deg)
      brightness(99%) contrast(90%);
    /* color: #008D41;  */
  }
}

.profile-shadow {
  box-shadow: 0px 20px 30px 0px #56c6ab40;
}

.search-description {
  max-height: calc(2 * 1.25rem * 14px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.search-description::after {
  /* Add an ellipsis to the end of the second line */
  content: '...';
  display: inline-block;
  width: 1em;
  margin-left: 0.2em;
}
